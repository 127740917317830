import React from 'react';
import ContantContainerMain from '../../total/ContantContainerMain';
import s from './CityCoatOfArms.module.css';
import { NavLink } from 'react-router-dom';
import { ROUTER } from '../../config';

/* Фотографии */
import mainImg from '../../assets/img/history/cityCoatOfArms/main_img.png';

/* Баннеры */
import FamilyYear from '../../BannersComopnents/Year';
import SVO from '../../BannersComopnents/svo';



const CityCoatOfArms = (props) => {

   return (
      <div>
         <ContantContainerMain>
            <div className={`mt80 breadcrumbs`}>
               <NavLink to={ROUTER.history.main} className='breadcrumbsFrom'>История</NavLink>
               <span className={'breadcrumbsTo'}> / Герб города</span>
            </div>
            <h1 className={`mt24 pageTitle`}>Герб города</h1>
            <div className="mt80 columnContainer">
               <div className="columnLarge">

                  <img className={s.imgMain} src={mainImg} alt="" />
                  <div className={`mt80 ${s.mainTextContainer}`}>
                     <div className={`pageSubtitle`}>Герб города Химки</div>
                     <div className={`mt38 text`}>
                        <p>
                           В чёрном поле — золотой, скачущий, крылатый Кентавр, который держит в распростёртых руках лук и стрелу.  Во главе, внизу и по сторонам кентавра — золотые звёзды.
                        </p>
                        <p>
                           Автор герба - художник Александр Мустафин. <br />
                           Дата принятия: 06.01.1998<br />
                           Номер в Геральдическом регистре РФ: 425
                        </p>
                        <p>
                           Именно такой вариант герба определен в 1996-1997 годах по итогам специального конкурса. В финал конкурса вышли три проекта: художника Александра Мустафина – «Кентавр», художника Евгения Потапова – «Стрижи» и председателя областного клуба геральдистов, химчанина Константина Моченова – «Дуб». Из трех работ комиссия отдала предпочтениям первым двум. Эти варианты направили для экспертизы в Герольдию при Президенте РФ, заместителю директора «Эрмитажа», главному герольдмейстеру, академику Георгию Вадимовичу Вилинбахову. Победу одержал проект «Кентавр».
                        </p>
                        <p>
                           <div className={`pageSubtitle`}>Символика герба:</div>
                           <br />
                           Кентавр со времён вавилонской и египетской культур символизирует единение разума человека и мощи природных сил, стремящихся к познанию неведомого и преодолению преград. Широко используется в греческой античной литературе, а из неё перешёл в русскую с принятием христианства..
                        </p>
                        <p>
                           На гербе Химок крылатый Кентавр — Стрелец, устремлённый вверх в окружении жёлтых звёзд. Он символизирует освоение космоса, которое тесно связано с историей города. Стремительный рост Химок обусловлен развитием предприятий авиационно-космического комплекса.
                        </p>
                        <p>
                           Чёрный цвет щита в геральдике соответствует цвету космоса и дополняет содержание герба. Жёлтый цвет (золото) — это цвет солнца, символ разума, справедливости, богатства и великодушия, знак земного и небесного величия.
                        </p>
                        <p>
                           Герб утвержден изначально как герб Химкинского района, в 2005 году район был преобразован в городской округ Химки.
                        </p>
                     </div>

                     <div className={`mt38 pageSubtitle`}>Гимн городского округа Химки</div>
                     <div className={`mt38 text`}>
                        <p>
                           Химки навеки в истории нашей,<br />
                           Память о подвиге нам дорога-<br />
                           Слава защитникам, насмерть стоявшим,<br />
                           И сокрушившим врага.
                        </p>
                        <p>
                           <span className="fw600">Припев:</span><br />
                           Химки - родной и любимый наш город,<br />
                           Славу тебе мы поем!<br />
                           Химки сегодня по- прежнему молод,<br />
                           Город, в котором живем!
                        </p>
                        <p>
                           В Химках проложена в космос дорога,<br />
                           Город науки и город труда,<br />
                           Для нашей Отчизны сделал ты много,<br />
                           В наших сердцах навсегда!
                        </p>
                        <p>
                           С каждой минутой мощней и красивей<br />
                           Города нашего славный полет!<br />
                           Вместе с народом, вместе с Россией<br />
                           Химки к победам идет!
                        </p>
                        <div className={`mt38 ${s.footnote}`}>
                           Слова А. Б. Моргунова, музыка Д. А. Дронова
                        </div>
                     </div>
                  </div>

               </div>
               <div className="ml20 columnSmal mobileNoneContainer">
                  <FamilyYear />
                  <div className="mt40">
                     <SVO />
                  </div>
               </div>
            </div>

         </ContantContainerMain>
      </div>
   )
}
export default CityCoatOfArms;
